/* Warning: please do not statically import any dependencies here except for static assets like icons */
// import icon from './file-code-solid.svg'
// import { ReactComponent as icon } from './file-code-solid.svg'
import { getAuthState } from '@sparky/framework';
import { FawkesCloudServicesResponse, FetchStatus } from './data/queryResultsDefinition';
import Logger from './shared/Logger';
import { Products, ZGet } from './shared/backend';
import PraUrls from './shared/urls';


const praNav: Object = {
    navigation: [{
    key: 'workflows',
    title: 'Workflows',
    expandable: true,
    path: '/workflows',
    noRBACheck: true,
    exact: false,
    mergeNav: true,
    children: [{
        key: 'privileged-remote-access',
        title: 'Privileged Remote Access',
        path: PraUrls.praBaseUrl,
        noRBACheck: true,
        contentClassName: 'panwds-tw3',
        component: () => import('./Routes'), // any component must be dynamically import like this
        children:[{
            key: 'pra-status',
            title: 'PRA Overview Status',
            path: PraUrls.praStatus,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
        }, 
        {
            key: 'pra-portal',
            title: 'PRA Portal',
            path: PraUrls.praPortal,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
        }, {
            key: 'pra-applications',
            title: 'Applications',
            path: PraUrls.praApplications,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
        }, {
            key: 'pra-profiles',
            title: 'PRA Profiles',
            path: PraUrls.praProfiles,
            noRBACheck: true,
            contentClassName: 'panwds-tw3',
        }, {
            key: 'pra-active-sessions',
            title: 'Active Sessions',
            path: PraUrls.praActiveSessions,
            noRBACheck: true,
        }]
    }],
}]
}

export default function init() {
    return async function tsgLoad() {
        try {
            if (await isFeatureEnabled()) {
                return praNav;
            }
        } catch (e) {
            const err = e as FetchStatus;
            Logger.error(
                `AppManagement: Error getting Fawkes cloud services - ${err?.status?.http_status}`
            );
        }
        return undefined
    };
};

async function isFeatureEnabled() {
    const authStateProperties = getAuthState()?.properties;

    // check if feature is enabled in fawkes cloud services
    const cloudServicesSettings = authStateProperties?.get('fawkes.cloudServices');
    const cloudServices = cloudServicesSettings?.[0] as FawkesCloudServicesResponse;
    const isEnabledInCloudServices = !!cloudServices?.mobile_users_agentless_access;
    if (isEnabledInCloudServices) {
        return true;
    }

    // check if feature is enabled in fawkes features (needed for panorama)
    const { toggles } = authStateProperties?.get('fawkes.features') || { toggles: [] };
    const enabledAsAFawkesFeature = !!toggles.find(
        (toggle: { t: 1 | 0; fn: string }) => toggle.t === 1 && toggle.fn === 'agentless-access-uda'
    );
    if (enabledAsAFawkesFeature) {
        return true;
    }

    // check if feature is enabled in fawkes cloud services by calling fawkes endpoint (last resort)
    const response = await ZGet(PraUrls.serverFawkesCloudServices, '', Products.FAWKES_JWT) as FawkesCloudServicesResponse[];
    const isEnabledInResponse = !!response?.[0]?.mobile_users_agentless_access;
    if (isEnabledInResponse) {
        return true;
    }
}