import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { en_strs } from './en/strings';
import Logger from '../shared/Logger';
import { DynamicFormattedMessageProps } from '@panwds/react-ui';

interface DateInfo {
    langStrings: Record<string, MessageDescriptor>;
    dateFmtString: string;
}

export const Supported_Locales = new Map<string, DateInfo>();
Supported_Locales.set('en', { langStrings: en_strs, dateFmtString: 'en-us' });

let strs: Record<string, MessageDescriptor> | undefined = en_strs;
let dateTimeFmt = 'en-us';

export const setLanguage = (lang: string): void => {
    Logger.info(`pra: setLanguage to ${lang}`);
    const localInfo =
        lang !== undefined && Supported_Locales.get(lang) === undefined
            ? Supported_Locales.get('en')
            : Supported_Locales.get(lang);
    strs = localInfo?.langStrings ? localInfo.langStrings : en_strs;
    dateTimeFmt = localInfo?.dateFmtString ? localInfo.dateFmtString : 'en_us';
};

export const localDateTimeString = (date?: Date): string => {
    const displayDate = date ? date : new Date();
    return displayDate.toLocaleString(dateTimeFmt, { timeZoneName: 'short' });
};

export const IntlString: React.FunctionComponent<{
    md: MessageDescriptor;
    cls?: string;
    values?: DynamicFormattedMessageProps['values'];
}> = ({ md, cls, values }) => {
    const clsName = cls ? cls : '';
    return md && md.id && md.defaultMessage ? (
        <span className={cls}>
            <FormattedMessage id={md.id} defaultMessage={md.defaultMessage} values={values} />
        </span>
    ) : (
        <span></span>
    );
};

export const getStrings = (): Record<string, MessageDescriptor> => {
    return strs as Record<string, MessageDescriptor>;
};
